import loadResource from './load-resource';
import ProgramConfig from './models/program-config';

const loadProgram = async (programUrl) => {
  try {
    const {data, headers} = await loadResource(programUrl);
    return new ProgramConfig(data, headers['x-df-geo-country']);
  } catch (e) {
    throw new Error(`Cannot load program: ${programUrl}`);
  }
};

export default loadProgram;

import ScenarioConfig from './scenario-config';
import InviteConfig from './invite-config';
import ContainerConfig from './container-config';
import SurveyConfig from './survey-config';

export default class ProgramConfig {
  constructor({programKey, countersUrl, eventsUrl, scenarios, invites, overlays: containers, surveys, cfrUrl}, userCountry) {
    this._programKey = programKey;
    this._countersUrl = countersUrl;
    this._eventsUrl = eventsUrl;
    this._cfrUrl = cfrUrl;
    this._scenarios = scenarios.map((x) => new ScenarioConfig(x));
    this._invites = invites.map((x) => new InviteConfig(x));
    this._containers = containers.map((x) => new ContainerConfig(x));
    this._surveys = surveys.map((x) => new SurveyConfig(x));
    this._userCountry = userCountry;
  }

  get programKey() {
    return this._programKey;
  }
  get countersUrl() {
    return this._countersUrl;
  }
  get eventsUrl() {
    return this._eventsUrl;
  }
  get cfrUrl() {
    return this._cfrUrl;
  }
  get scenarios() {
    return this._scenarios;
  }
  get invites() {
    return this._invites;
  }
  get containers() {
    return this._containers;
  }
  get surveys() {
    return this._surveys;
  }

  get userCountry() {
    return this._userCountry;
  }
}
